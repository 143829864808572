import { faGithub } from '@fortawesome/free-brands-svg-icons'
import { faHeart } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { StaticImage } from 'gatsby-plugin-image'
import * as React from "react"
import Helmet from 'react-helmet'
import ogp from '../images/ogp.png'

const classNames = {
  link: 'text-cyan-600 hover:underline',
  dataStructure: 'bg-slate-100 p-4 pt-8 mt-6 before:content-["Data_Structure"] relative before:absolute before:top-1 before:right-2 before:text-slate-500 before:text-sm space-y-8',
  example: 'bg-slate-100 p-4 pt-8 mt-8 before:content-["example"] relative before:absolute before:top-1 before:right-2 before:text-slate-500 space-y-8',
}

const BASE_URL = 'https://dlhn.org'

const IndexPage = () => {
  return (
    <>
      <Helmet defer={false}>
        <title>DLHN : Blazing fast and small data serialization format</title>
        <meta content={`${BASE_URL}${ogp}`} property="og:image" />
        <meta content="DLHN" property="og:site_name" />
        <meta content="DLHN" property="og:title" />
        <meta content={BASE_URL} property="og:url" />
        <meta content="website" property="og:type" />
        <meta content="DLHN : Blazing fast and small data serialization format" property="og:description" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:url" content={BASE_URL} />
      </Helmet>
      <div className='w-full max-w-full px-2 lg:max-w-screen-xl mx-auto'>
        <header className='sticky top-0 w-full flex-none py-5 bg-white z-10 border-b'>
          <div className='relative flex text-3xl'>
            <a href='/' className='hover:text-slate-500' onClick={() => window.scrollTo(0, 0)}>DLHN</a>
            <div className='flex lg:flex ml-auto items-center space-x-1 text-xl'>
              <div>
                <a href='https://github.com/sponsors/otake84' alt='donate' className='text-rose-500 hover:text-rose-400 p-1.5 group'><FontAwesomeIcon icon={faHeart} /><span className='text-xs inline-block opacity-0 w-0 group-hover:ml-1.5 group-hover:opacity-100 group-hover:w-11 transition-all duration-500 overflow-hidden'>Donate</span></a>
              </div>
              <div>
                <a href='https://github.com/otake84/dlhn' alt='github' className='hover:text-slate-500 p-1.5'><FontAwesomeIcon icon={faGithub} /></a>
              </div>
            </div>
          </div>
        </header>
        <div className='relative flex mt-8'>
          <nav className='fixed w-52 hidden lg:block'>
            <ul className='space-y-1.5'>
              <li><a href='#overview' className='hover:text-slate-500'>Overview</a></li>
              <li><a href='#data-structures' className='hover:text-slate-500'>Data Structures</a></li>
              <li><a href='#types' className='hover:text-slate-500'>Types</a></li>
              <li><a href='#header' className='hover:text-slate-500'>Header</a></li>
              <li><a href='#body' className='hover:text-slate-500'>Body</a></li>
            </ul>
          </nav>
          <main className='lg:ml-56 mb-2'>
            <div className='text-center'>
              <StaticImage src='../images/dlhn.png' alt='DLHN' quality={90} width={500} />
            </div>
            <section id='overview'>
              <h2 className='text-2xl mb-3 pb-2.5 border-b font-semibold'>Overview</h2>
              <div className='text-slate-700'>
                <p>DLHN ( Pronounced the same as "Dullahan" ) is a language and platform neutral binary serialization format that is inspired by JSON, CSV, MessagePack, and Protocol Buffers. It is designed for blazing fast serialization and deserialization with the smallest possible data size without the need for schema file. However, we are also considering supporting schema file in the future.</p>
              </div>
            </section>

            <section id='data-structures' className="mt-20 pt-10">
              <h2 className='text-2xl mb-3 pb-2.5 border-b font-semibold'>Data Structures</h2>
              <p>DLHN has a header and a body part, which can be separated from each other. It also supports Stream.</p>
              <div className='flex flex-col mt-10 space-y-16'>
                <section className='space-y-2'>
                  <h3 className='text-xl font-semibold'>Header</h3>
                  <p>Header only.</p>
                  <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                    <thead>
                      <tr>
                        <th className='border-collapse border border-slate-400 text-center p-2'>Header</th>
                      </tr>
                    </thead>
                  </table>
                </section>

                <section className='space-y-2'>
                  <h3 className='text-xl font-semibold'>Body</h3>
                  <p>Body only. The most efficient format. However, the deserializer must know the header information in advance.</p>
                  <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                    <thead>
                      <tr>
                        <th className='border-collapse border border-slate-400 text-center p-2'>Body</th>
                      </tr>
                    </thead>
                  </table>
                </section>

                <section className='space-y-2'>
                  <h3 className='text-xl font-semibold'>Header + Body</h3>
                  <p>Header followed by body. Unlike the body-only case, this format does not require the deserializer to know the header information in advance.</p>
                  <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                    <thead>
                      <tr>
                        <th className='border-collapse border border-slate-400 text-center p-2'>Header</th>
                        <th className='border-collapse border border-slate-400 text-center p-2'>Body</th>
                      </tr>
                    </thead>
                  </table>
                </section>

                <section className='space-y-2'>
                  <h3 className='text-xl font-semibold'>Header (Stream)</h3>
                  <p>Stream version with headers only.</p>
                  <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                    <thead>
                      <tr>
                        <th className='border-collapse border border-slate-400 text-center p-2'>Header</th>
                        <th className='border-collapse border border-slate-400 text-center p-2'>Header</th>
                        <th className='border-collapse border border-slate-400 text-center p-2'>Header</th>
                        <th className='border-collapse border border-slate-400 text-center p-2'>Header..</th>
                      </tr>
                    </thead>
                  </table>
                </section>

                <section className='space-y-2'>
                  <h3 className='text-xl font-semibold'>Body (Stream)</h3>
                  <p>Stream version with bodies only.</p>
                  <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                    <thead>
                      <tr>
                        <th className='border-collapse border border-slate-400 text-center p-2'>Body</th>
                        <th className='border-collapse border border-slate-400 text-center p-2'>Body</th>
                        <th className='border-collapse border border-slate-400 text-center p-2'>Body</th>
                        <th className='border-collapse border border-slate-400 text-center p-2'>Body..</th>
                      </tr>
                    </thead>
                  </table>
                </section>

                <section className='space-y-2'>
                  <h3 className='text-xl font-semibold'>Header + Body (Stream)</h3>
                  <p>Stream version with a header and bodies. All bodies must have the same type as the first header.</p>
                  <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                    <thead>
                      <tr>
                        <th className='border-collapse border border-slate-400 text-center p-2'>Header</th>
                        <th className='border-collapse border border-slate-400 text-center p-2'>Body</th>
                        <th className='border-collapse border border-slate-400 text-center p-2'>Body</th>
                        <th className='border-collapse border border-slate-400 text-center p-2'>Body</th>
                        <th className='border-collapse border border-slate-400 text-center p-2'>Body..</th>
                      </tr>
                    </thead>
                  </table>
                </section>

                <section className='space-y-2'>
                  <h3 className='text-xl font-semibold'>(Header + Body) (Stream)</h3>
                  <p>Stream version with headers and bodies. Similar to Header + Body (Stream) but can store different types of data in each pair.</p>
                  <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                    <thead>
                      <tr>
                        <th className='border-collapse border border-slate-400 text-center p-2'>Header</th>
                        <th className='border-collapse border border-slate-400 text-center p-2'>Body</th>
                        <th className='border-collapse border border-slate-400 text-center p-2'>Header</th>
                        <th className='border-collapse border border-slate-400 text-center p-2'>Body</th>
                        <th className='border-collapse border border-slate-400 text-center p-2'>Header</th>
                        <th className='border-collapse border border-slate-400 text-center p-2'>Body</th>
                        <th className='border-collapse border border-slate-400 text-center p-2'>..</th>
                      </tr>
                    </thead>
                  </table>
                </section>
              </div>
            </section>

            <section id='types' className="mt-20 pt-10">
              <h2 className='text-2xl mb-3 pb-2.5 border-b font-semibold'>Types</h2>
              <div className='flex flex-col space-y-16'>
                <section className='space-y-2'>
                  <h3 className='text-xl font-semibold'>Unit</h3>
                  <p>Represents an Unit.</p>
                </section>

                <section className='space-y-2'>
                  <h3 className='text-xl font-semibold'>Optional&lt;T&gt;</h3>
                  <p>Represents some that contains a T-type value or none that does not contain a value.</p>
                </section>

                <section className='space-y-2'>
                  <h3 className='text-xl font-semibold'>Boolean</h3>
                  <p>Represents a boolean.</p>
                </section>

                <section className='space-y-2'>
                  <h3 className='text-xl font-semibold'>UInt8</h3>
                  <p>Represents an 8-bit unsigned integer.</p>
                </section>

                <section className='space-y-2'>
                  <h3 className='text-xl font-semibold'>UInt16</h3>
                  <p>Represents a 16-bit unsigned integer.</p>
                </section>

                <section className='space-y-2'>
                  <h3 className='text-xl font-semibold'>UInt32</h3>
                  <p>Represents a 32-bit unsigned integer.</p>
                </section>

                <section className='space-y-2'>
                  <h3 className='text-xl font-semibold'>UInt64</h3>
                  <p>Represents a 64-bit unsigned integer.</p>
                </section>

                <section className='space-y-2'>
                  <h3 className='text-xl font-semibold'>Int8</h3>
                  <p>Represents an 8-bit signed integer.</p>
                </section>

                <section className='space-y-2'>
                  <h3 className='text-xl font-semibold'>Int16</h3>
                  <p>Represents a 16-bit signed integer.</p>
                </section>

                <section className='space-y-2'>
                  <h3 className='text-xl font-semibold'>Int32</h3>
                  <p>Represents a 32-bit signed integer.</p>
                </section>

                <section className='space-y-2'>
                  <h3 className='text-xl font-semibold'>Int64</h3>
                  <p>Represents a 64-bit signed integer.</p>
                </section>

                <section className='space-y-2'>
                  <h3 className='text-xl font-semibold'>Float32</h3>
                  <p>Represents an IEEE 754 single precision floating point number.</p>
                </section>

                <section className='space-y-2'>
                  <h3 className='text-xl font-semibold'>Float64</h3>
                  <p>Represents an IEEE 754 double precision floating point number.</p>
                </section>

                <section className='space-y-2'>
                  <h3 className='text-xl font-semibold'>BigUInt</h3>
                  <p>Represents an arbitrary precision unsigned integer.</p>
                </section>

                <section className='space-y-2'>
                  <h3 className='text-xl font-semibold'>BigInt</h3>
                  <p>Represents an arbitrary precision signed integer.</p>
                </section>

                <section className='space-y-2'>
                  <h3 className='text-xl font-semibold'>BigDecimal</h3>
                  <p>Represents an arbitrary precision signed decimal number.</p>
                </section>

                <section className='space-y-2'>
                  <h3 className='text-xl font-semibold'>String</h3>
                  <p>Represents an UTF-8 string.</p>
                </section>

                <section className='space-y-2'>
                  <h3 className='text-xl font-semibold'>Binary</h3>
                  <p>Represents a byte array.</p>
                </section>

                <section className='space-y-2'>
                  <h3 className='text-xl font-semibold'>Array&lt;T&gt;</h3>
                  <p>Represents a sequence of values of the same type T.</p>
                </section>

                <section className='space-y-2'>
                  <h3 className='text-xl font-semibold'>Tuple&lt;(T1, ..)&gt;</h3>
                  <p>Represents a collection of values of different types.</p>
                </section>

                {/* <section className='space-y-2'>
                  <h3 className='text-xl font-semibold'>Struct {'{'} field1: T1, field..: T.. ,{'}'}</h3>
                  <p>TODO!</p>
                </section> */}

                <section className='space-y-2'>
                  <h3 className='text-xl font-semibold'>Map&lt;T&gt;</h3>
                  <p>Represents a collection of key value pairs of string key type and same value type.</p>
                </section>

                <section className='space-y-2'>
                  <h3 className='text-xl font-semibold'>Enum {'{'} Field1(T1), Field..(T..), {'}'}</h3>
                  <p>Represents a custom defined type which may be one of a few different variants.</p>
                </section>

                <section className='space-y-2'>
                  <h3 className='text-xl font-semibold'>Date</h3>
                  <p>Represents a proleptic Gregorian calendar date.</p>
                </section>

                <section className='space-y-2'>
                  <h3 className='text-xl font-semibold'>DateTime</h3>
                  <p>Represents a TimeZone independent moment in nanoseconds starting from 1970-01-01T00:00Z.</p>
                </section>
              </div>
            </section>

            <section id='header' className="mt-20 pt-10">
              <h2 className='text-2xl mb-3 pb-2.5 border-b font-semibold'>Header</h2>
              <div className='flex flex-col space-y-16'>
                <section>
                  <h3 className='text-xl font-semibold'>Unit</h3>
                  <table className='mt-2 border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                    <thead className='bg-slate-50'>
                      <tr>
                        <th className='border-collapse border border-slate-400 text-center p-2'>Unit code</th>
                      </tr>
                    </thead>
                    <tbody className='bg-white'>
                      <tr>
                        <td className='border-collapse border border-slate-400 text-center p-2'>0x00</td>
                      </tr>
                    </tbody>
                  </table>
                </section>

                <section>
                  <h3 className='text-xl font-semibold'>Optional&lt;T&gt;</h3>
                  <table className='mt-2 border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                    <thead className='bg-slate-50'>
                      <tr>
                        <th className='border-collapse border border-slate-400 text-center p-2'>Optional code</th>
                        <th className='border-collapse border border-slate-400 text-center p-2'>&lt;T&gt;</th>
                      </tr>
                    </thead>
                    <tbody className='bg-white'>
                      <tr>
                        <td className='border-collapse border border-slate-400 text-center p-2'>0x01</td>
                        <td className='border-collapse border border-slate-400 text-center p-2'>serialize_header(T)</td>
                      </tr>
                    </tbody>
                  </table>
                  <div className='w-full bg-slate-100 p-4 mt-6 before:content-["example"] relative before:absolute before:top-1 before:right-2 before:text-slate-500'>
                    <p>Optional&lt;Boolean&gt;</p>
                    <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                      <thead className='bg-slate-50'>
                        <tr>
                          <th className='border-collapse border border-slate-400 text-center p-2'>Optional code</th>
                          <th className='border-collapse border border-slate-400 text-center p-2'>Boolean code</th>
                        </tr>
                      </thead>
                      <tbody className='bg-white'>
                        <tr>
                          <td className='border-collapse border border-slate-400 text-center p-2'>0x01</td>
                          <td className='border-collapse border border-slate-400 text-center p-2'>0x02</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </section>

                <section>
                  <h3 className='text-xl font-semibold'>Boolean</h3>
                  <table className='mt-2 border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                    <thead className='bg-slate-50'>
                      <tr>
                        <th className='border-collapse border border-slate-400 text-center p-2'>Boolean code</th>
                      </tr>
                    </thead>
                    <tbody className='bg-white'>
                      <tr>
                        <td className='border-collapse border border-slate-400 text-center p-2'>0x02</td>
                      </tr>
                    </tbody>
                  </table>
                </section>

                <section>
                  <h3 className='text-xl font-semibold'>UInt8</h3>
                  <table className='mt-2 border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                    <thead className='bg-slate-50'>
                      <tr>
                        <th className='border-collapse border border-slate-400 text-center p-2'>UInt8 code</th>
                      </tr>
                    </thead>
                    <tbody className='bg-white'>
                      <tr>
                        <td className='border-collapse border border-slate-400 text-center p-2'>0x03</td>
                      </tr>
                    </tbody>
                  </table>
                </section>

                <section>
                  <h3 className='text-xl font-semibold'>UInt16</h3>
                  <table className='mt-2 border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                    <thead className='bg-slate-50'>
                      <tr>
                        <th className='border-collapse border border-slate-400 text-center p-2'>UInt16 code</th>
                      </tr>
                    </thead>
                    <tbody className='bg-white'>
                      <tr>
                        <td className='border-collapse border border-slate-400 text-center p-2'>0x04</td>
                      </tr>
                    </tbody>
                  </table>
                </section>

                <section>
                  <h3 className='text-xl font-semibold'>UInt32</h3>
                  <table className='mt-2 border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                    <thead className='bg-slate-50'>
                      <tr>
                        <th className='border-collapse border border-slate-400 text-center p-2'>UInt32 code</th>
                      </tr>
                    </thead>
                    <tbody className='bg-white'>
                      <tr>
                        <td className='border-collapse border border-slate-400 text-center p-2'>0x05</td>
                      </tr>
                    </tbody>
                  </table>
                </section>

                <section>
                  <h3 className='text-xl font-semibold'>UInt64</h3>
                  <table className='mt-2 border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                    <thead className='bg-slate-50'>
                      <tr>
                        <th className='border-collapse border border-slate-400 text-center p-2'>UInt64 code</th>
                      </tr>
                    </thead>
                    <tbody className='bg-white'>
                      <tr>
                        <td className='border-collapse border border-slate-400 text-center p-2'>0x06</td>
                      </tr>
                    </tbody>
                  </table>
                </section>

                {/* <section>
                  <h3 className='text-xl font-semibold'>UInt128</h3>
                  <table className='mt-2 border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                    <thead className='bg-slate-50'>
                      <tr>
                        <th className='border-collapse border border-slate-400 text-center p-2'>UInt128 code</th>
                      </tr>
                    </thead>
                    <tbody className='bg-white'>
                      <tr>
                        <td className='border-collapse border border-slate-400 text-center p-2'>0x07</td>
                      </tr>
                    </tbody>
                  </table>
                </section> */}

                <section>
                  <h3 className='text-xl font-semibold'>Int8</h3>
                  <table className='mt-2 border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                    <thead className='bg-slate-50'>
                      <tr>
                        <th className='border-collapse border border-slate-400 text-center p-2'>Int8 code</th>
                      </tr>
                    </thead>
                    <tbody className='bg-white'>
                      <tr>
                        <td className='border-collapse border border-slate-400 text-center p-2'>0x08</td>
                      </tr>
                    </tbody>
                  </table>
                </section>

                <section>
                  <h3 className='text-xl font-semibold'>Int16</h3>
                  <table className='mt-2 border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                    <thead className='bg-slate-50'>
                      <tr>
                        <th className='border-collapse border border-slate-400 text-center p-2'>Int16 code</th>
                      </tr>
                    </thead>
                    <tbody className='bg-white'>
                      <tr>
                        <td className='border-collapse border border-slate-400 text-center p-2'>0x09</td>
                      </tr>
                    </tbody>
                  </table>
                </section>

                <section>
                  <h3 className='text-xl font-semibold'>Int32</h3>
                  <table className='mt-2 border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                    <thead className='bg-slate-50'>
                      <tr>
                        <th className='border-collapse border border-slate-400 text-center p-2'>Int32 code</th>
                      </tr>
                    </thead>
                    <tbody className='bg-white'>
                      <tr>
                        <td className='border-collapse border border-slate-400 text-center p-2'>0x0a</td>
                      </tr>
                    </tbody>
                  </table>
                </section>

                <section>
                  <h3 className='text-xl font-semibold'>Int64</h3>
                  <table className='mt-2 border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                    <thead className='bg-slate-50'>
                      <tr>
                        <th className='border-collapse border border-slate-400 text-center p-2'>Int64 code</th>
                      </tr>
                    </thead>
                    <tbody className='bg-white'>
                      <tr>
                        <td className='border-collapse border border-slate-400 text-center p-2'>0x0b</td>
                      </tr>
                    </tbody>
                  </table>
                </section>

                {/* <section>
                  <h3 className='text-xl font-semibold'>Int128</h3>
                  <table className='mt-2 border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                    <thead className='bg-slate-50'>
                      <tr>
                        <th className='border-collapse border border-slate-400 text-center p-2'>Int128 code</th>
                      </tr>
                    </thead>
                    <tbody className='bg-white'>
                      <tr>
                        <td className='border-collapse border border-slate-400 text-center p-2'>0x0c</td>
                      </tr>
                    </tbody>
                  </table>
                </section> */}

                <section>
                  <h3 className='text-xl font-semibold'>Float32</h3>
                  <table className='mt-2 border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                    <thead className='bg-slate-50'>
                      <tr>
                        <th className='border-collapse border border-slate-400 text-center p-2'>Float32 code</th>
                      </tr>
                    </thead>
                    <tbody className='bg-white'>
                      <tr>
                        <td className='border-collapse border border-slate-400 text-center p-2'>0x0d</td>
                      </tr>
                    </tbody>
                  </table>
                </section>

                <section>
                  <h3 className='text-xl font-semibold'>Float64</h3>
                  <table className='mt-2 border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                    <thead className='bg-slate-50'>
                      <tr>
                        <th className='border-collapse border border-slate-400 text-center p-2'>Float64 code</th>
                      </tr>
                    </thead>
                    <tbody className='bg-white'>
                      <tr>
                        <td className='border-collapse border border-slate-400 text-center p-2'>0x0e</td>
                      </tr>
                    </tbody>
                  </table>
                </section>

                <section>
                  <h3 className='text-xl font-semibold'>BigUInt</h3>
                  <table className='mt-2 border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                    <thead className='bg-slate-50'>
                      <tr>
                        <th className='border-collapse border border-slate-400 text-center p-2'>BigUInt code</th>
                      </tr>
                    </thead>
                    <tbody className='bg-white'>
                      <tr>
                        <td className='border-collapse border border-slate-400 text-center p-2'>0x0f</td>
                      </tr>
                    </tbody>
                  </table>
                </section>

                <section>
                  <h3 className='text-xl font-semibold'>BigInt</h3>
                  <table className='mt-2 border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                    <thead className='bg-slate-50'>
                      <tr>
                        <th className='border-collapse border border-slate-400 text-center p-2'>BigInt code</th>
                      </tr>
                    </thead>
                    <tbody className='bg-white'>
                      <tr>
                        <td className='border-collapse border border-slate-400 text-center p-2'>0x10</td>
                      </tr>
                    </tbody>
                  </table>
                </section>

                <section>
                  <h3 className='text-xl font-semibold'>BigDecimal</h3>
                  <table className='mt-2 border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                    <thead className='bg-slate-50'>
                      <tr>
                        <th className='border-collapse border border-slate-400 text-center p-2'>BigDecimal code</th>
                      </tr>
                    </thead>
                    <tbody className='bg-white'>
                      <tr>
                        <td className='border-collapse border border-slate-400 text-center p-2'>0x11</td>
                      </tr>
                    </tbody>
                  </table>
                </section>

                <section>
                  <h3 className='text-xl font-semibold'>String</h3>
                  <table className='mt-2 border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                    <thead className='bg-slate-50'>
                      <tr>
                        <th className='border-collapse border border-slate-400 text-center p-2'>String code</th>
                      </tr>
                    </thead>
                    <tbody className='bg-white'>
                      <tr>
                        <td className='border-collapse border border-slate-400 text-center p-2'>0x12</td>
                      </tr>
                    </tbody>
                  </table>
                </section>

                <section>
                  <h3 className='text-xl font-semibold'>Binary</h3>
                  <table className='mt-2 border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                    <thead className='bg-slate-50'>
                      <tr>
                        <th className='border-collapse border border-slate-400 text-center p-2'>Binary code</th>
                      </tr>
                    </thead>
                    <tbody className='bg-white'>
                      <tr>
                        <td className='border-collapse border border-slate-400 text-center p-2'>0x13</td>
                      </tr>
                    </tbody>
                  </table>
                </section>

                <section>
                  <h3 className='text-xl font-semibold'>Array&lt;T&gt;</h3>
                  <table className='mt-2 border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                    <thead className='bg-slate-50'>
                      <tr>
                        <th className='border-collapse border border-slate-400 text-center p-2'>Array code</th>
                        <th className='border-collapse border border-slate-400 text-center p-2'>&lt;T&gt;</th>
                      </tr>
                    </thead>
                    <tbody className='bg-white'>
                      <tr>
                        <td className='border-collapse border border-slate-400 text-center p-2'>0x14</td>
                        <td className='border-collapse border border-slate-400 text-center p-2'>serialize_header(T)</td>
                      </tr>
                    </tbody>
                  </table>
                  <div className='bg-slate-100 p-4 mt-6 before:content-["example"] relative before:absolute before:top-1 before:right-2 before:text-slate-500'>
                    <p>Array&lt;Boolean&gt;</p>
                    <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                      <thead className='bg-slate-50'>
                        <tr>
                          <th className='border-collapse border border-slate-400 text-center p-2'>Array code</th>
                          <th className='border-collapse border border-slate-400 text-center p-2'>Boolean code</th>
                        </tr>
                      </thead>
                      <tbody className='bg-white'>
                        <tr>
                          <td className='border-collapse border border-slate-400 text-center p-2'>0x14</td>
                          <td className='border-collapse border border-slate-400 text-center p-2'>0x02</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </section>

                <section>
                  <h3 className='text-xl font-semibold'>Tuple&lt;(T1, ..)&gt;</h3>
                  <table className='mt-2 border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                    <thead className='bg-slate-50'>
                      <tr>
                        <th className='border-collapse border border-slate-400 text-center p-2'>Tuple code</th>
                        <th className='border-collapse border border-slate-400 text-center p-2'>Number of fields in Tuple : <a href='#body-uint16' className={classNames.link}>UInt16</a></th>
                        <th className='border-collapse border border-slate-400 text-center p-2'>&lt;T1&gt;</th>
                        <th className='border-collapse border border-slate-400 text-center p-2'>&lt;..&gt;</th>
                      </tr>
                    </thead>
                    <tbody className='bg-white'>
                      <tr>
                        <td className='border-collapse border border-slate-400 text-center p-2'>0x15</td>
                        <td className='border-collapse border border-slate-400 text-center p-2'>Number of fields in Tuple</td>
                        <td className='border-collapse border border-slate-400 text-center p-2'>serialize_header(T1)</td>
                        <td className='border-collapse border border-slate-400 text-center p-2'>serialize_header(..)</td>
                      </tr>
                    </tbody>
                  </table>
                  <div className='bg-slate-100 p-4 mt-6 before:content-["example"] relative before:absolute before:top-1 before:right-2 before:text-slate-500'>
                    <p>Tuple&lt;(Boolean, UInt8, String)&gt;</p>
                    <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                      <thead className='bg-slate-50'>
                        <tr>
                          <th className='border-collapse border border-slate-400 text-center p-2'>Tuple code</th>
                          <th className='border-collapse border border-slate-400 text-center p-2'>Number of fields in Tuple : <a href='#body-uint16' className={classNames.link}>UInt16</a></th>
                          <th className='border-collapse border border-slate-400 text-center p-2'>Boolean code</th>
                          <th className='border-collapse border border-slate-400 text-center p-2'>UInt8 code</th>
                          <th className='border-collapse border border-slate-400 text-center p-2'>String code</th>
                        </tr>
                      </thead>
                      <tbody className='bg-white'>
                        <tr>
                          <td className='border-collapse border border-slate-400 text-center p-2'>0x15</td>
                          <td className='border-collapse border border-slate-400 text-center p-2'>0x03</td>
                          <td className='border-collapse border border-slate-400 text-center p-2'>0x02</td>
                          <td className='border-collapse border border-slate-400 text-center p-2'>0x03</td>
                          <td className='border-collapse border border-slate-400 text-center p-2'>0x12</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </section>

                {/* <section>
                  <h3 className='text-xl font-semibold'>Struct {'{'} field1: T1, field..: T.. ,{'}'}</h3>
                  <table className='mt-2 border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                    <thead className='bg-slate-50'>
                      <tr>
                        <th className='border-collapse border border-slate-400 text-center p-2'>Struct code</th>
                        <th className='border-collapse border border-slate-400 text-center p-2'>Number of fields in Struct (64bit unsigned integer encoded with PrefixVarint)</th>
                        <th className='border-collapse border border-slate-400 text-center p-2'>&lt;T1&gt;</th>
                        <th className='border-collapse border border-slate-400 text-center p-2'>&lt;T..&gt;</th>
                      </tr>
                    </thead>
                    <tbody className='bg-white'>
                      <tr>
                        <td className='border-collapse border border-slate-400 text-center p-2'>0x16</td>
                        <td className='border-collapse border border-slate-400 text-center p-2'>encode_prefix_varint(Number of fields in Struct)</td>
                        <td className='border-collapse border border-slate-400 text-center p-2'>serialize_header(T1)</td>
                        <td className='border-collapse border border-slate-400 text-center p-2'>serialize_header(T..)</td>
                      </tr>
                    </tbody>
                  </table>
                  <div className='bg-slate-100 p-4 mt-6 before:content-["example"] relative before:absolute before:top-1 before:right-2 before:text-slate-500'>
                    <p>Struct {'{'} field1: Boolean, field2: UInt8, field3: String {'}'}</p>
                    <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                      <thead className='bg-slate-50'>
                        <tr>
                          <th className='border-collapse border border-slate-400 text-center p-2'>Struct code</th>
                          <th className='border-collapse border border-slate-400 text-center p-2'>Number of fields in Struct (64bit unsigned integer encoded with PrefixVarint)</th>
                          <th className='border-collapse border border-slate-400 text-center p-2'>Boolean code</th>
                          <th className='border-collapse border border-slate-400 text-center p-2'>UInt8 code</th>
                          <th className='border-collapse border border-slate-400 text-center p-2'>String code</th>
                        </tr>
                      </thead>
                      <tbody className='bg-white'>
                        <tr>
                          <td className='border-collapse border border-slate-400 text-center p-2'>0x16</td>
                          <td className='border-collapse border border-slate-400 text-center p-2'>0x03</td>
                          <td className='border-collapse border border-slate-400 text-center p-2'>0x02</td>
                          <td className='border-collapse border border-slate-400 text-center p-2'>0x03</td>
                          <td className='border-collapse border border-slate-400 text-center p-2'>0x12</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </section> */}

                <section>
                  <h3 className='text-xl font-semibold'>Map&lt;T&gt;</h3>
                  <table className='mt-2 border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                    <thead className='bg-slate-50'>
                      <tr>
                        <th className='border-collapse border border-slate-400 text-center p-2'>Map code</th>
                        <th className='border-collapse border border-slate-400 text-center p-2'>&lt;T&gt;</th>
                      </tr>
                    </thead>
                    <tbody className='bg-white'>
                      <tr>
                        <td className='border-collapse border border-slate-400 text-center p-2'>0x17</td>
                        <td className='border-collapse border border-slate-400 text-center p-2'>serialize_header(T)</td>
                      </tr>
                    </tbody>
                  </table>

                  <div className='bg-slate-100 p-4 mt-6 before:content-["example"] relative before:absolute before:top-1 before:right-2 before:text-slate-500'>
                    <p>Map&lt;Boolean&gt;</p>
                    <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                      <thead className='bg-slate-50'>
                        <tr>
                          <th className='border-collapse border border-slate-400 text-center p-2'>Map code</th>
                          <th className='border-collapse border border-slate-400 text-center p-2'>Boolean code</th>
                        </tr>
                      </thead>
                      <tbody className='bg-white'>
                        <tr>
                          <td className='border-collapse border border-slate-400 text-center p-2'>0x17</td>
                          <td className='border-collapse border border-slate-400 text-center p-2'>0x03</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </section>

                <section>
                  <h3 className='text-xl font-semibold'>Enum {'{'} Field1(T1), Field..(T..), {'}'}</h3>
                  <table className='mt-2 border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                    <thead className='bg-slate-50'>
                      <tr>
                        <th className='border-collapse border border-slate-400 text-center p-2'>Enum code</th>
                        <th className='border-collapse border border-slate-400 text-center p-2'>Number of fields in Enum : <a href='#body-uint16' className={classNames.link}>UInt16</a></th>
                        <th className='border-collapse border border-slate-400 text-center p-2'>&lt;T1&gt;</th>
                        <th className='border-collapse border border-slate-400 text-center p-2'>&lt;T..&gt;</th>
                      </tr>
                    </thead>
                    <tbody className='bg-white'>
                      <tr>
                        <td className='border-collapse border border-slate-400 text-center p-2'>0x18</td>
                        <td className='border-collapse border border-slate-400 text-center p-2'>Number of fields in Enum</td>
                        <td className='border-collapse border border-slate-400 text-center p-2'>serialize_header(T1)</td>
                        <td className='border-collapse border border-slate-400 text-center p-2'>serialize_header(T..)</td>
                      </tr>
                    </tbody>
                  </table>
                  <div className='bg-slate-100 p-4 mt-6 before:content-["example"] relative before:absolute before:top-1 before:right-2 before:text-slate-500'>
                    <p>Enum {'{'} Field1(Boolean), Field2(UInt8), Field3((Boolean, String)) {'}'}</p>
                    <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                      <thead className='bg-slate-50'>
                        <tr>
                          <th className='border-collapse border border-slate-400 text-center p-2'>Enum code</th>
                          <th className='border-collapse border border-slate-400 text-center p-2'>Number of fields in Enum : <a href='#body-uint16' className={classNames.link}>UInt16</a></th>
                          <th className='border-collapse border border-slate-400 text-center p-2'>Boolean code</th>
                          <th className='border-collapse border border-slate-400 text-center p-2'>UInt8 code</th>
                          <th className='border-collapse border border-slate-400 text-center p-2'>Tuple code</th>
                          <th className='border-collapse border border-slate-400 text-center p-2'>Number of fields in Tuple : <a href='#body-uint16' className={classNames.link}>UInt16</a></th>
                          <th className='border-collapse border border-slate-400 text-center p-2'>Boolean code</th>
                          <th className='border-collapse border border-slate-400 text-center p-2'>String code</th>
                        </tr>
                      </thead>
                      <tbody className='bg-white'>
                        <tr>
                          <td className='border-collapse border border-slate-400 text-center p-2'>0x18</td>
                          <td className='border-collapse border border-slate-400 text-center p-2'>0x03</td>
                          <td className='border-collapse border border-slate-400 text-center p-2'>0x02</td>
                          <td className='border-collapse border border-slate-400 text-center p-2'>0x03</td>
                          <td className='border-collapse border border-slate-400 text-center p-2'>0x15</td>
                          <td className='border-collapse border border-slate-400 text-center p-2'>0x02</td>
                          <td className='border-collapse border border-slate-400 text-center p-2'>0x02</td>
                          <td className='border-collapse border border-slate-400 text-center p-2'>0x12</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </section>

                <section>
                  <h3 className='text-xl font-semibold'>Date</h3>
                  <table className='mt-2 border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                    <thead className='bg-slate-50'>
                      <tr>
                        <th className='border-collapse border border-slate-400 text-center p-2'>Date code</th>
                      </tr>
                    </thead>
                    <tbody className='bg-white'>
                      <tr>
                        <td className='border-collapse border border-slate-400 text-center p-2'>0x19</td>
                      </tr>
                    </tbody>
                  </table>
                </section>

                <section>
                  <h3 className='text-xl font-semibold'>DateTime</h3>
                  <table className='mt-2 border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                    <thead className='bg-slate-50'>
                      <tr>
                        <th className='border-collapse border border-slate-400 text-center p-2'>DateTime code</th>
                      </tr>
                    </thead>
                    <tbody className='bg-white'>
                      <tr>
                        <td className='border-collapse border border-slate-400 text-center p-2'>0x1a</td>
                      </tr>
                    </tbody>
                  </table>
                </section>
              </div>
            </section>

            <section id='body' className="mt-20 pt-10">
              <h2 className='text-2xl mb-3 pb-2.5 border-b font-semibold'>Body</h2>
              <div className='flex flex-col space-y-20'>
                <section>
                  <h3 className='text-xl font-semibold'>Unit</h3>
                  <p className='mt-1 after:text-slate-500 after:content-[":_Data_Size"] after:text-xs after:ml-1'>0 byte</p>
                  <p className='mt-4'>
                    A value of type Unit. Not a single byte is written during serialization, and not a single byte is read during deserialization. If you want to make it Nullable, use the Optional type.
                  </p>
                  <div className='bg-slate-100 p-5 mt-6 before:content-["Data_Structure"] relative before:absolute before:top-1 before:right-2 before:text-slate-500 before:text-sm'>
                    <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                      <thead className='bg-slate-50'>
                        <tr>
                          <th className='border-collapse border border-slate-400 text-center p-2'>Unit</th>
                        </tr>
                      </thead>
                      <tbody className='bg-white'>
                        <tr>
                          <td className='border-collapse border border-slate-400 text-center p-4'></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </section>

                <section>
                  <h3 className='text-xl font-semibold'>Optional&lt;T&gt;</h3>
                  <p className='mt-1 after:text-slate-500 after:content-[":_Data_Size"] after:text-xs after:ml-1'>1 ~ N bytes</p>
                  <p className='mt-4'>
                    Optional can store a single value of type T inside, and has a state of None or Some(value: T).
                  </p>
                  <div className={classNames.dataStructure}>
                    <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                      <thead className='bg-slate-50'>
                        <tr>
                          <th className='border-collapse border border-slate-400 text-center p-2'>None</th>
                        </tr>
                      </thead>
                      <tbody className='bg-white'>
                        <tr>
                          <td className='border-collapse border border-slate-400 text-center p-2'>0x00</td>
                        </tr>
                      </tbody>
                    </table>
                    <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                      <thead className='bg-slate-50'>
                        <tr>
                          <th className='border-collapse border border-slate-400 text-center p-2'>Some</th>
                          <th className='border-collapse border border-slate-400 text-center p-2'>value: T</th>
                        </tr>
                      </thead>
                      <tbody className='bg-white'>
                        <tr>
                          <td className='border-collapse border border-slate-400 text-center p-2'>0x01</td>
                          <td className='border-collapse border border-slate-400 text-center p-2'>serialize_body(value)</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className={classNames.example}>
                    <div className='space-y-2'>
                      <p>None: Optional&lt;Boolean&gt;</p>
                      <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                        <thead className='bg-slate-50'>
                          <tr>
                            <th className='border-collapse border border-slate-400 text-center p-2'>None</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0x00</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className='space-y-2'>
                      <p>Some(true): Optional&lt;Boolean&gt;</p>
                      <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                        <thead className='bg-slate-50'>
                          <tr>
                            <th className='border-collapse border border-slate-400 text-center p-2'>Some</th>
                            <th className='border-collapse border border-slate-400 text-center p-2'>value: Boolean</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0x01</td>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0x01</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </section>

                <section>
                  <h3 className='text-xl font-semibold'>Boolean</h3>
                  <p className='mt-1 after:text-slate-500 after:content-[":_Data_Size"] after:text-xs after:ml-1'>1 byte</p>
                  <p className='mt-4'>
                    Stores a true or false value.
                  </p>
                  <div className={classNames.dataStructure}>
                    <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                      <thead className='bg-slate-50'>
                        <tr>
                          <th className='border-collapse border border-slate-400 text-center p-2'>value: Boolean</th>
                        </tr>
                      </thead>
                      <tbody className='bg-white'>
                        <tr>
                          <td className='border-collapse border border-slate-400 text-center p-2'>serialize_body(value: Boolean)</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className={classNames.example}>
                    <div className='space-y-2'>
                      <p>false</p>
                      <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                        <thead className='bg-slate-50'>
                          <tr>
                            <th className='border-collapse border border-slate-400 text-center p-2'>Boolean value</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0x00</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className='space-y-2'>
                      <p>true</p>
                      <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                        <thead className='bg-slate-50'>
                          <tr>
                            <th className='border-collapse border border-slate-400 text-center p-2'>Boolean value</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0x01</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </section>

                <section>
                  <h3 className='text-xl font-semibold'>UInt8</h3>
                  <p className='mt-1 after:text-slate-500 after:content-[":_Data_Size"] after:text-xs after:ml-1'>1 byte</p>
                  <p className='mt-4'>
                    An 8-bit little-endian unsigned integer. (Note: <span className='font-semibold'>PrefixVarint is not used</span>)
                  </p>
                  <div className={classNames.dataStructure}>
                    <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                      <thead className='bg-slate-50'>
                        <tr>
                          <th className='border-collapse border border-slate-400 text-center p-2'>value: UInt8</th>
                        </tr>
                      </thead>
                      <tbody className='bg-white'>
                        <tr>
                          <td className='border-collapse border border-slate-400 text-center p-2'>serialize_body(value: UInt8)</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className={classNames.example}>
                    <div className='space-y-2'>
                      <p>0</p>
                      <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                        <thead className='bg-slate-50'>
                          <tr>
                            <th className='border-collapse border border-slate-400 text-center p-2'>value: UInt8</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0x00</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className='space-y-2'>
                      <p>1</p>
                      <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                        <thead className='bg-slate-50'>
                          <tr>
                            <th className='border-collapse border border-slate-400 text-center p-2'>value: UInt8</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0x01</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className='space-y-2'>
                      <p>255</p>
                      <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                        <thead className='bg-slate-50'>
                          <tr>
                            <th className='border-collapse border border-slate-400 text-center p-2'>value: UInt8</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0xff</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </section>

                <section id='body-uint16'>
                  <h3 className='text-xl font-semibold'>UInt16</h3>
                  <p className='mt-1 after:text-slate-500 after:content-[":_Data_Size"] after:text-xs after:ml-1'>1 ~ 3 bytes</p>
                  <p className='mt-4'>
                    16bit unsigned integer. Encode with PrefixVarint.
                  </p>
                  <div className={classNames.dataStructure}>
                    <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                      <thead className='bg-slate-50'>
                        <tr>
                          <th className='border-collapse border border-slate-400 text-center p-2'>value: UInt16</th>
                        </tr>
                      </thead>
                      <tbody className='bg-white'>
                        <tr>
                          <td className='border-collapse border border-slate-400 text-center p-2'>serialize_body(value: UInt16)</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className={classNames.example}>
                    <div className='space-y-2'>
                      <p>0</p>
                      <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                        <thead className='bg-slate-50'>
                          <tr>
                            <th className='border-collapse border border-slate-400 text-center p-2'>value: UInt16</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0x00</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className='space-y-2'>
                      <p>1</p>
                      <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                        <thead className='bg-slate-50'>
                          <tr>
                            <th className='border-collapse border border-slate-400 text-center p-2'>value: UInt16</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0x01</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className='space-y-2'>
                      <p>127</p>
                      <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                        <thead className='bg-slate-50'>
                          <tr>
                            <th className='border-collapse border border-slate-400 text-center p-2'>value: UInt16</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0x7f</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className='space-y-2'>
                      <p>128</p>
                      <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                        <thead className='bg-slate-50'>
                          <tr>
                            <th className='border-collapse border border-slate-400 text-center p-2'>value: UInt16</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0x8002</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className='space-y-2'>
                      <p>16383</p>
                      <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                        <thead className='bg-slate-50'>
                          <tr>
                            <th className='border-collapse border border-slate-400 text-center p-2'>value: UInt16</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0xbfff</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className='space-y-2'>
                      <p>16384</p>
                      <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                        <thead className='bg-slate-50'>
                          <tr>
                            <th className='border-collapse border border-slate-400 text-center p-2'>value: UInt16</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0xc00040</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className='space-y-2'>
                      <p>65535</p>
                      <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                        <thead className='bg-slate-50'>
                          <tr>
                            <th className='border-collapse border border-slate-400 text-center p-2'>value: UInt16</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0xc0ffff</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </section>

                <section id='body-uint32'>
                  <h3 className='text-xl font-semibold'>UInt32</h3>
                  <p className='mt-1 after:text-slate-500 after:content-[":_Data_Size"] after:text-xs after:ml-1'>1 ~ 5 bytes</p>
                  <p className='mt-4'>
                    32bit unsigned integer. Encode with PrefixVarint.
                  </p>
                  <div className={classNames.dataStructure}>
                    <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                      <thead className='bg-slate-50'>
                        <tr>
                          <th className='border-collapse border border-slate-400 text-center p-2'>value: UInt32</th>
                        </tr>
                      </thead>
                      <tbody className='bg-white'>
                        <tr>
                          <td className='border-collapse border border-slate-400 text-center p-2'>serialize_body(value: UInt32)</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className={classNames.example}>
                    <div className='space-y-2'>
                      <p>0</p>
                      <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                        <thead className='bg-slate-50'>
                          <tr>
                            <th className='border-collapse border border-slate-400 text-center p-2'>value: UInt32</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0x00</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className='space-y-2'>
                      <p>1</p>
                      <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                        <thead className='bg-slate-50'>
                          <tr>
                            <th className='border-collapse border border-slate-400 text-center p-2'>value: UInt32</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0x01</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className='space-y-2'>
                      <p>127</p>
                      <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                        <thead className='bg-slate-50'>
                          <tr>
                            <th className='border-collapse border border-slate-400 text-center p-2'>value: UInt32</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0x7f</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className='space-y-2'>
                      <p>128</p>
                      <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                        <thead className='bg-slate-50'>
                          <tr>
                            <th className='border-collapse border border-slate-400 text-center p-2'>value: UInt32</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0x8002</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className='space-y-2'>
                      <p>16383</p>
                      <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                        <thead className='bg-slate-50'>
                          <tr>
                            <th className='border-collapse border border-slate-400 text-center p-2'>value: UInt32</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0xbfff</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className='space-y-2'>
                      <p>16384</p>
                      <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                        <thead className='bg-slate-50'>
                          <tr>
                            <th className='border-collapse border border-slate-400 text-center p-2'>value: UInt32</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0xc00002</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className='space-y-2'>
                      <p>2097151</p>
                      <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                        <thead className='bg-slate-50'>
                          <tr>
                            <th className='border-collapse border border-slate-400 text-center p-2'>value: UInt32</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0xdfffff</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className='space-y-2'>
                      <p>2097152</p>
                      <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                        <thead className='bg-slate-50'>
                          <tr>
                            <th className='border-collapse border border-slate-400 text-center p-2'>value: UInt32</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0xe0000002</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className='space-y-2'>
                      <p>268435455</p>
                      <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                        <thead className='bg-slate-50'>
                          <tr>
                            <th className='border-collapse border border-slate-400 text-center p-2'>value: UInt32</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0xefffffff</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className='space-y-2'>
                      <p>268435456</p>
                      <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                        <thead className='bg-slate-50'>
                          <tr>
                            <th className='border-collapse border border-slate-400 text-center p-2'>value: UInt32</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0xf000000010</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className='space-y-2'>
                      <p>4294967295</p>
                      <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                        <thead className='bg-slate-50'>
                          <tr>
                            <th className='border-collapse border border-slate-400 text-center p-2'>value: UInt32</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0xf0ffffffff</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </section>

                <section id='body-uint64'>
                  <h3 className='text-xl font-semibold'>UInt64</h3>
                  <p className='mt-1 after:text-slate-500 after:content-[":_Data_Size"] after:text-xs after:ml-1'>1 ~ 9 bytes</p>
                  <p className='mt-4'>
                    64bit unsigned integer. Encode with PrefixVarint.
                  </p>
                  <div className={classNames.dataStructure}>
                    <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                      <thead className='bg-slate-50'>
                        <tr>
                          <th className='border-collapse border border-slate-400 text-center p-2'>value: UInt64</th>
                        </tr>
                      </thead>
                      <tbody className='bg-white'>
                        <tr>
                          <td className='border-collapse border border-slate-400 text-center p-2'>serialize_body(value: UInt64)</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className={classNames.example}>
                    <div className='space-y-2'>
                      <p>0</p>
                      <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                        <thead className='bg-slate-50'>
                          <tr>
                            <th className='border-collapse border border-slate-400 text-center p-2'>value: UInt64</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0x00</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className='space-y-2'>
                      <p>1</p>
                      <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                        <thead className='bg-slate-50'>
                          <tr>
                            <th className='border-collapse border border-slate-400 text-center p-2'>value: UInt64</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0x01</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className='space-y-2'>
                      <p>127</p>
                      <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                        <thead className='bg-slate-50'>
                          <tr>
                            <th className='border-collapse border border-slate-400 text-center p-2'>value: UInt64</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0x7f</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className='space-y-2'>
                      <p>128</p>
                      <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                        <thead className='bg-slate-50'>
                          <tr>
                            <th className='border-collapse border border-slate-400 text-center p-2'>value: UInt64</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0x8002</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className='space-y-2'>
                      <p>16383</p>
                      <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                        <thead className='bg-slate-50'>
                          <tr>
                            <th className='border-collapse border border-slate-400 text-center p-2'>value: UInt64</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0xbfff</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className='space-y-2'>
                      <p>16384</p>
                      <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                        <thead className='bg-slate-50'>
                          <tr>
                            <th className='border-collapse border border-slate-400 text-center p-2'>value: UInt64</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0xc00002</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className='space-y-2'>
                      <p>2097151</p>
                      <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                        <thead className='bg-slate-50'>
                          <tr>
                            <th className='border-collapse border border-slate-400 text-center p-2'>value: UInt64</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0xdfffff</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className='space-y-2'>
                      <p>2097152</p>
                      <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                        <thead className='bg-slate-50'>
                          <tr>
                            <th className='border-collapse border border-slate-400 text-center p-2'>value: UInt64</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0xe0000002</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className='space-y-2'>
                      <p>268435455</p>
                      <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                        <thead className='bg-slate-50'>
                          <tr>
                            <th className='border-collapse border border-slate-400 text-center p-2'>value: UInt64</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0xefffffff</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className='space-y-2'>
                      <p>268435456</p>
                      <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                        <thead className='bg-slate-50'>
                          <tr>
                            <th className='border-collapse border border-slate-400 text-center p-2'>value: UInt64</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0xf000000002</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className='space-y-2'>
                      <p>34359738367</p>
                      <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                        <thead className='bg-slate-50'>
                          <tr>
                            <th className='border-collapse border border-slate-400 text-center p-2'>value: UInt64</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0xf7ffffffff</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className='space-y-2'>
                      <p>34359738368</p>
                      <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                        <thead className='bg-slate-50'>
                          <tr>
                            <th className='border-collapse border border-slate-400 text-center p-2'>value: UInt64</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0xf80000000002</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className='space-y-2'>
                      <p>4398046511103</p>
                      <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                        <thead className='bg-slate-50'>
                          <tr>
                            <th className='border-collapse border border-slate-400 text-center p-2'>value: UInt64</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0xfbffffffffff</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className='space-y-2'>
                      <p>4398046511104</p>
                      <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                        <thead className='bg-slate-50'>
                          <tr>
                            <th className='border-collapse border border-slate-400 text-center p-2'>value: UInt64</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0xfc000000000002</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className='space-y-2'>
                      <p>562949953421311</p>
                      <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                        <thead className='bg-slate-50'>
                          <tr>
                            <th className='border-collapse border border-slate-400 text-center p-2'>value: UInt64</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0xfdffffffffffff</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className='space-y-2'>
                      <p>562949953421312</p>
                      <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                        <thead className='bg-slate-50'>
                          <tr>
                            <th className='border-collapse border border-slate-400 text-center p-2'>value: UInt64</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0xfe00000000000002</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className='space-y-2'>
                      <p>72057594037927935</p>
                      <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                        <thead className='bg-slate-50'>
                          <tr>
                            <th className='border-collapse border border-slate-400 text-center p-2'>value: UInt64</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0xfeffffffffffffff</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className='space-y-2'>
                      <p>72057594037927936</p>
                      <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                        <thead className='bg-slate-50'>
                          <tr>
                            <th className='border-collapse border border-slate-400 text-center p-2'>value: UInt64</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0xff0000000000000001</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className='space-y-2'>
                      <p>18446744073709551615</p>
                      <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                        <thead className='bg-slate-50'>
                          <tr>
                            <th className='border-collapse border border-slate-400 text-center p-2'>value: UInt64</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0xffffffffffffffffff</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </section>

                {/* <section>
                  <h3 className='text-xl font-semibold'>UInt128</h3>
                </section> */}

                <section>
                  <h3 className='text-xl font-semibold'>Int8</h3>
                  <p className='mt-1 after:text-slate-500 after:content-[":_Data_Size"] after:text-xs after:ml-1'>1 byte</p>
                  <p className='mt-4'>
                    An 8-bit little-endian integer. (Note: <span className='font-semibold'>ZigZag encoding and PrefixVarint are not used</span>)
                  </p>
                  <div className={classNames.dataStructure}>
                    <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                      <thead className='bg-slate-50'>
                        <tr>
                          <th className='border-collapse border border-slate-400 text-center p-2'>value: Int8</th>
                        </tr>
                      </thead>
                      <tbody className='bg-white'>
                        <tr>
                          <td className='border-collapse border border-slate-400 text-center p-2'>serialize_body(value: Int8)</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className={classNames.example}>
                    <div className='space-y-2'>
                      <p>-128</p>
                      <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                        <thead className='bg-slate-50'>
                          <tr>
                            <th className='border-collapse border border-slate-400 text-center p-2'>value: Int8</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0x80</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className='space-y-2'>
                      <p>-1</p>
                      <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                        <thead className='bg-slate-50'>
                          <tr>
                            <th className='border-collapse border border-slate-400 text-center p-2'>value: Int8</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0xff</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className='space-y-2'>
                      <p>0</p>
                      <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                        <thead className='bg-slate-50'>
                          <tr>
                            <th className='border-collapse border border-slate-400 text-center p-2'>value: Int8</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0x00</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className='space-y-2'>
                      <p>1</p>
                      <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                        <thead className='bg-slate-50'>
                          <tr>
                            <th className='border-collapse border border-slate-400 text-center p-2'>value: Int8</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0x01</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className='space-y-2'>
                      <p>127</p>
                      <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                        <thead className='bg-slate-50'>
                          <tr>
                            <th className='border-collapse border border-slate-400 text-center p-2'>value: Int8</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0x7f</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </section>

                <section id='body-int16'>
                  <h3 className='text-xl font-semibold'>Int16</h3>
                  <p className='mt-1 after:text-slate-500 after:content-[":_Data_Size"] after:text-xs after:ml-1'>1 ~ 3 bytes</p>
                  <p className='mt-4'>
                    16bit integer. Encode with ZigZag encoding and PrefixVarint.
                  </p>
                  <div className={classNames.dataStructure}>
                    <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                      <thead className='bg-slate-50'>
                        <tr>
                          <th className='border-collapse border border-slate-400 text-center p-2'>value: Int16</th>
                        </tr>
                      </thead>
                      <tbody className='bg-white'>
                        <tr>
                          <td className='border-collapse border border-slate-400 text-center p-2'>serialize_body(value: Int16)</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className={classNames.example}>
                    <div className='space-y-2'>
                      <p>-32768</p>
                      <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                        <thead className='bg-slate-50'>
                          <tr>
                            <th className='border-collapse border border-slate-400 text-center p-2'>value: Int16</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0xc0ffff</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className='space-y-2'>
                      <p>-8193</p>
                      <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                        <thead className='bg-slate-50'>
                          <tr>
                            <th className='border-collapse border border-slate-400 text-center p-2'>value: Int16</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0xc00140</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className='space-y-2'>
                      <p>-8192</p>
                      <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                        <thead className='bg-slate-50'>
                          <tr>
                            <th className='border-collapse border border-slate-400 text-center p-2'>value: Int16</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0xbfff</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className='space-y-2'>
                      <p>-65</p>
                      <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                        <thead className='bg-slate-50'>
                          <tr>
                            <th className='border-collapse border border-slate-400 text-center p-2'>value: Int16</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0x8102</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className='space-y-2'>
                      <p>-64</p>
                      <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                        <thead className='bg-slate-50'>
                          <tr>
                            <th className='border-collapse border border-slate-400 text-center p-2'>value: Int16</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0x7f</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className='space-y-2'>
                      <p>-1</p>
                      <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                        <thead className='bg-slate-50'>
                          <tr>
                            <th className='border-collapse border border-slate-400 text-center p-2'>value: Int16</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0x01</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className='space-y-2'>
                      <p>0</p>
                      <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                        <thead className='bg-slate-50'>
                          <tr>
                            <th className='border-collapse border border-slate-400 text-center p-2'>value: Int16</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0x00</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className='space-y-2'>
                      <p>1</p>
                      <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                        <thead className='bg-slate-50'>
                          <tr>
                            <th className='border-collapse border border-slate-400 text-center p-2'>value: Int16</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0x02</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className='space-y-2'>
                      <p>63</p>
                      <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                        <thead className='bg-slate-50'>
                          <tr>
                            <th className='border-collapse border border-slate-400 text-center p-2'>value: Int16</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0x7e</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className='space-y-2'>
                      <p>64</p>
                      <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                        <thead className='bg-slate-50'>
                          <tr>
                            <th className='border-collapse border border-slate-400 text-center p-2'>value: Int16</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0x8002</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className='space-y-2'>
                      <p>8191</p>
                      <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                        <thead className='bg-slate-50'>
                          <tr>
                            <th className='border-collapse border border-slate-400 text-center p-2'>value: Int16</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0xbeff</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className='space-y-2'>
                      <p>8192</p>
                      <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                        <thead className='bg-slate-50'>
                          <tr>
                            <th className='border-collapse border border-slate-400 text-center p-2'>value: Int16</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0xc00040</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className='space-y-2'>
                      <p>32767</p>
                      <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                        <thead className='bg-slate-50'>
                          <tr>
                            <th className='border-collapse border border-slate-400 text-center p-2'>value: Int16</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0xc0feff</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </section>

                <section id='body-int32'>
                  <h3 className='text-xl font-semibold'>Int32</h3>
                  <p className='mt-1 after:text-slate-500 after:content-[":_Data_Size"] after:text-xs after:ml-1'>1 ~ 5 bytes</p>
                  <p className='mt-4'>
                    32bit integer. Encode with ZigZag encoding and PrefixVarint.
                  </p>
                  <div className={classNames.dataStructure}>
                    <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                      <thead className='bg-slate-50'>
                        <tr>
                          <th className='border-collapse border border-slate-400 text-center p-2'>value: Int32</th>
                        </tr>
                      </thead>
                      <tbody className='bg-white'>
                        <tr>
                          <td className='border-collapse border border-slate-400 text-center p-2'>serialize_body(value: Int32)</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className={classNames.example}>
                    <div className='space-y-2'>
                      <p>-2147483648</p>
                      <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                        <thead className='bg-slate-50'>
                          <tr>
                            <th className='border-collapse border border-slate-400 text-center p-2'>value: Int32</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0xf0ffffffff</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className='space-y-2'>
                      <p>-134217729</p>
                      <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                        <thead className='bg-slate-50'>
                          <tr>
                            <th className='border-collapse border border-slate-400 text-center p-2'>value: Int32</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0xf001000010</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className='space-y-2'>
                      <p>-134217728</p>
                      <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                        <thead className='bg-slate-50'>
                          <tr>
                            <th className='border-collapse border border-slate-400 text-center p-2'>value: Int32</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0xefffffff</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className='space-y-2'>
                      <p>-1048577</p>
                      <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                        <thead className='bg-slate-50'>
                          <tr>
                            <th className='border-collapse border border-slate-400 text-center p-2'>value: Int32</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0xe1000002</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className='space-y-2'>
                      <p>-1048576</p>
                      <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                        <thead className='bg-slate-50'>
                          <tr>
                            <th className='border-collapse border border-slate-400 text-center p-2'>value: Int32</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0xdfffff</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className='space-y-2'>
                      <p>-8193</p>
                      <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                        <thead className='bg-slate-50'>
                          <tr>
                            <th className='border-collapse border border-slate-400 text-center p-2'>value: Int32</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0xc10002</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className='space-y-2'>
                      <p>-8192</p>
                      <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                        <thead className='bg-slate-50'>
                          <tr>
                            <th className='border-collapse border border-slate-400 text-center p-2'>value: Int32</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0xbfff</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className='space-y-2'>
                      <p>-65</p>
                      <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                        <thead className='bg-slate-50'>
                          <tr>
                            <th className='border-collapse border border-slate-400 text-center p-2'>value: Int32</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0x8102</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className='space-y-2'>
                      <p>-64</p>
                      <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                        <thead className='bg-slate-50'>
                          <tr>
                            <th className='border-collapse border border-slate-400 text-center p-2'>value: Int32</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0x7f</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className='space-y-2'>
                      <p>-1</p>
                      <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                        <thead className='bg-slate-50'>
                          <tr>
                            <th className='border-collapse border border-slate-400 text-center p-2'>value: Int32</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0x01</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className='space-y-2'>
                      <p>0</p>
                      <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                        <thead className='bg-slate-50'>
                          <tr>
                            <th className='border-collapse border border-slate-400 text-center p-2'>value: Int32</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0x00</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className='space-y-2'>
                      <p>1</p>
                      <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                        <thead className='bg-slate-50'>
                          <tr>
                            <th className='border-collapse border border-slate-400 text-center p-2'>value: Int32</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0x02</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className='space-y-2'>
                      <p>63</p>
                      <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                        <thead className='bg-slate-50'>
                          <tr>
                            <th className='border-collapse border border-slate-400 text-center p-2'>value: Int32</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0x7e</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className='space-y-2'>
                      <p>64</p>
                      <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                        <thead className='bg-slate-50'>
                          <tr>
                            <th className='border-collapse border border-slate-400 text-center p-2'>value: Int32</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0x8002</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className='space-y-2'>
                      <p>8191</p>
                      <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                        <thead className='bg-slate-50'>
                          <tr>
                            <th className='border-collapse border border-slate-400 text-center p-2'>value: Int32</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0xbeff</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className='space-y-2'>
                      <p>8192</p>
                      <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                        <thead className='bg-slate-50'>
                          <tr>
                            <th className='border-collapse border border-slate-400 text-center p-2'>value: Int32</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0xc00002</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className='space-y-2'>
                      <p>1048575</p>
                      <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                        <thead className='bg-slate-50'>
                          <tr>
                            <th className='border-collapse border border-slate-400 text-center p-2'>value: Int32</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0xdeffff</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className='space-y-2'>
                      <p>1048576</p>
                      <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                        <thead className='bg-slate-50'>
                          <tr>
                            <th className='border-collapse border border-slate-400 text-center p-2'>value: Int32</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0xe0000002</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className='space-y-2'>
                      <p>134217727</p>
                      <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                        <thead className='bg-slate-50'>
                          <tr>
                            <th className='border-collapse border border-slate-400 text-center p-2'>value: Int32</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0xeeffffff</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className='space-y-2'>
                      <p>134217728</p>
                      <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                        <thead className='bg-slate-50'>
                          <tr>
                            <th className='border-collapse border border-slate-400 text-center p-2'>value: Int32</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0xf000000010</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className='space-y-2'>
                      <p>2147483647</p>
                      <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                        <thead className='bg-slate-50'>
                          <tr>
                            <th className='border-collapse border border-slate-400 text-center p-2'>value: Int32</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0xf0feffffff</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </section>

                <section id='body-int64'>
                  <h3 className='text-xl font-semibold'>Int64</h3>
                  <p className='mt-1 after:text-slate-500 after:content-[":_Data_Size"] after:text-xs after:ml-1'>1 ~ 9 bytes</p>
                  <p className='mt-4'>
                    64bit integer. Encode with ZigZag encoding and PrefixVarint.
                  </p>
                  <div className={classNames.dataStructure}>
                    <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                      <thead className='bg-slate-50'>
                        <tr>
                          <th className='border-collapse border border-slate-400 text-center p-2'>value: Int64</th>
                        </tr>
                      </thead>
                      <tbody className='bg-white'>
                        <tr>
                          <td className='border-collapse border border-slate-400 text-center p-2'>serialize_body(value: Int64)</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className={classNames.example}>
                    <div className='space-y-2'>
                      <p>-9223372036854775808</p>
                      <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                        <thead className='bg-slate-50'>
                          <tr>
                            <th className='border-collapse border border-slate-400 text-center p-2'>value: Int64</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0xffffffffffffffffff</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className='space-y-2'>
                      <p>-36028797018963969</p>
                      <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                        <thead className='bg-slate-50'>
                          <tr>
                            <th className='border-collapse border border-slate-400 text-center p-2'>value: Int64</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0xff0100000000000001</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className='space-y-2'>
                      <p>-36028797018963968</p>
                      <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                        <thead className='bg-slate-50'>
                          <tr>
                            <th className='border-collapse border border-slate-400 text-center p-2'>value: Int64</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0xfeffffffffffffff</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className='space-y-2'>
                      <p>-281474976710657</p>
                      <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                        <thead className='bg-slate-50'>
                          <tr>
                            <th className='border-collapse border border-slate-400 text-center p-2'>value: Int64</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0xfe01000000000002</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className='space-y-2'>
                      <p>-281474976710656</p>
                      <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                        <thead className='bg-slate-50'>
                          <tr>
                            <th className='border-collapse border border-slate-400 text-center p-2'>value: Int64</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0xfdffffffffffff</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className='space-y-2'>
                      <p>-2199023255553</p>
                      <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                        <thead className='bg-slate-50'>
                          <tr>
                            <th className='border-collapse border border-slate-400 text-center p-2'>value: Int64</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0xfd000000000002</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className='space-y-2'>
                      <p>-2199023255552</p>
                      <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                        <thead className='bg-slate-50'>
                          <tr>
                            <th className='border-collapse border border-slate-400 text-center p-2'>value: Int64</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0xfbffffffffff</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className='space-y-2'>
                      <p>-17179869185</p>
                      <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                        <thead className='bg-slate-50'>
                          <tr>
                            <th className='border-collapse border border-slate-400 text-center p-2'>value: Int64</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0xf90000000002</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className='space-y-2'>
                      <p>-17179869184</p>
                      <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                        <thead className='bg-slate-50'>
                          <tr>
                            <th className='border-collapse border border-slate-400 text-center p-2'>value: Int64</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0xf7ffffffff</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className='space-y-2'>
                      <p>-134217729</p>
                      <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                        <thead className='bg-slate-50'>
                          <tr>
                            <th className='border-collapse border border-slate-400 text-center p-2'>value: Int64</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0xf100000002</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className='space-y-2'>
                      <p>-134217728</p>
                      <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                        <thead className='bg-slate-50'>
                          <tr>
                            <th className='border-collapse border border-slate-400 text-center p-2'>value: Int64</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0xefffffff</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className='space-y-2'>
                      <p>-1048577</p>
                      <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                        <thead className='bg-slate-50'>
                          <tr>
                            <th className='border-collapse border border-slate-400 text-center p-2'>value: Int64</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0xe1000002</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className='space-y-2'>
                      <p>-1048576</p>
                      <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                        <thead className='bg-slate-50'>
                          <tr>
                            <th className='border-collapse border border-slate-400 text-center p-2'>value: Int64</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0xdfffff</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className='space-y-2'>
                      <p>-8193</p>
                      <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                        <thead className='bg-slate-50'>
                          <tr>
                            <th className='border-collapse border border-slate-400 text-center p-2'>value: Int64</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0xc10002</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className='space-y-2'>
                      <p>-8192</p>
                      <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                        <thead className='bg-slate-50'>
                          <tr>
                            <th className='border-collapse border border-slate-400 text-center p-2'>value: Int64</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0xbfff</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className='space-y-2'>
                      <p>-65</p>
                      <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                        <thead className='bg-slate-50'>
                          <tr>
                            <th className='border-collapse border border-slate-400 text-center p-2'>value: Int64</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0x8102</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className='space-y-2'>
                      <p>-64</p>
                      <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                        <thead className='bg-slate-50'>
                          <tr>
                            <th className='border-collapse border border-slate-400 text-center p-2'>value: Int64</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0x7f</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className='space-y-2'>
                      <p>-1</p>
                      <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                        <thead className='bg-slate-50'>
                          <tr>
                            <th className='border-collapse border border-slate-400 text-center p-2'>value: Int64</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0x01</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className='space-y-2'>
                      <p>0</p>
                      <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                        <thead className='bg-slate-50'>
                          <tr>
                            <th className='border-collapse border border-slate-400 text-center p-2'>value: Int64</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0x00</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className='space-y-2'>
                      <p>1</p>
                      <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                        <thead className='bg-slate-50'>
                          <tr>
                            <th className='border-collapse border border-slate-400 text-center p-2'>value: Int64</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0x02</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className='space-y-2'>
                      <p>63</p>
                      <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                        <thead className='bg-slate-50'>
                          <tr>
                            <th className='border-collapse border border-slate-400 text-center p-2'>value: Int64</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0x7e</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className='space-y-2'>
                      <p>64</p>
                      <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                        <thead className='bg-slate-50'>
                          <tr>
                            <th className='border-collapse border border-slate-400 text-center p-2'>value: Int64</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0x8002</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className='space-y-2'>
                      <p>8191</p>
                      <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                        <thead className='bg-slate-50'>
                          <tr>
                            <th className='border-collapse border border-slate-400 text-center p-2'>value: Int64</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0xbeff</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className='space-y-2'>
                      <p>8192</p>
                      <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                        <thead className='bg-slate-50'>
                          <tr>
                            <th className='border-collapse border border-slate-400 text-center p-2'>value: Int64</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0xc00002</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className='space-y-2'>
                      <p>1048575</p>
                      <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                        <thead className='bg-slate-50'>
                          <tr>
                            <th className='border-collapse border border-slate-400 text-center p-2'>value: Int64</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0xdeffff</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className='space-y-2'>
                      <p>1048576</p>
                      <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                        <thead className='bg-slate-50'>
                          <tr>
                            <th className='border-collapse border border-slate-400 text-center p-2'>value: Int64</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0xe0000002</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className='space-y-2'>
                      <p>134217727</p>
                      <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                        <thead className='bg-slate-50'>
                          <tr>
                            <th className='border-collapse border border-slate-400 text-center p-2'>value: Int64</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0xeeffffff</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className='space-y-2'>
                      <p>134217728</p>
                      <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                        <thead className='bg-slate-50'>
                          <tr>
                            <th className='border-collapse border border-slate-400 text-center p-2'>value: Int64</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0xf000000002</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className='space-y-2'>
                      <p>17179869183</p>
                      <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                        <thead className='bg-slate-50'>
                          <tr>
                            <th className='border-collapse border border-slate-400 text-center p-2'>value: Int64</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0xf6ffffffff</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className='space-y-2'>
                      <p>17179869184</p>
                      <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                        <thead className='bg-slate-50'>
                          <tr>
                            <th className='border-collapse border border-slate-400 text-center p-2'>value: Int64</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0xf80000000002</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className='space-y-2'>
                      <p>2199023255551</p>
                      <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                        <thead className='bg-slate-50'>
                          <tr>
                            <th className='border-collapse border border-slate-400 text-center p-2'>value: Int64</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0xfaffffffffff</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className='space-y-2'>
                      <p>2199023255552</p>
                      <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                        <thead className='bg-slate-50'>
                          <tr>
                            <th className='border-collapse border border-slate-400 text-center p-2'>value: Int64</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0xfc000000000002</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className='space-y-2'>
                      <p>281474976710655</p>
                      <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                        <thead className='bg-slate-50'>
                          <tr>
                            <th className='border-collapse border border-slate-400 text-center p-2'>value: Int64</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0xfcffffffffffff</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className='space-y-2'>
                      <p>281474976710656</p>
                      <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                        <thead className='bg-slate-50'>
                          <tr>
                            <th className='border-collapse border border-slate-400 text-center p-2'>value: Int64</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0xfe00000000000002</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className='space-y-2'>
                      <p>36028797018963967</p>
                      <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                        <thead className='bg-slate-50'>
                          <tr>
                            <th className='border-collapse border border-slate-400 text-center p-2'>value: Int64</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0xfefeffffffffffff</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className='space-y-2'>
                      <p>36028797018963968</p>
                      <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                        <thead className='bg-slate-50'>
                          <tr>
                            <th className='border-collapse border border-slate-400 text-center p-2'>value: Int64</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0xff0000000000000001</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className='space-y-2'>
                      <p>9223372036854775807</p>
                      <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                        <thead className='bg-slate-50'>
                          <tr>
                            <th className='border-collapse border border-slate-400 text-center p-2'>value: Int64</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0xfffeffffffffffffff</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </section>

                {/* <section>
                  <h3 className='text-xl font-semibold'>Int128</h3>
                </section> */}

                <section>
                  <h3 className='text-xl font-semibold'>Float32</h3>
                  <p className='mt-1 after:text-slate-500 after:content-[":_Data_Size"] after:text-xs after:ml-1'>4 bytes</p>
                  <p className='mt-4'>
                    32bit float. Stores IEEE 754 single precision floating point number format in little endian.
                  </p>
                  <div className={classNames.dataStructure}>
                    <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                      <thead className='bg-slate-50'>
                        <tr>
                          <th className='border-collapse border border-slate-400 text-center p-2'>value: Float32</th>
                        </tr>
                      </thead>
                      <tbody className='bg-white'>
                        <tr>
                          <td className='border-collapse border border-slate-400 text-center p-2'>serialize_body(value: Float32)</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className={classNames.example}>
                    <div className='space-y-2'>
                      <p>-Infinity</p>
                      <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                        <thead className='bg-slate-50'>
                          <tr>
                            <th className='border-collapse border border-slate-400 text-center p-2'>value: Float32</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0x000080ff</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className='space-y-2'>
                      <p>-1.1</p>
                      <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                        <thead className='bg-slate-50'>
                          <tr>
                            <th className='border-collapse border border-slate-400 text-center p-2'>value: Float32</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0xcdcc8cbf</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className='space-y-2'>
                      <p>0</p>
                      <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                        <thead className='bg-slate-50'>
                          <tr>
                            <th className='border-collapse border border-slate-400 text-center p-2'>value: Float32</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0x00000000</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className='space-y-2'>
                      <p>1.1</p>
                      <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                        <thead className='bg-slate-50'>
                          <tr>
                            <th className='border-collapse border border-slate-400 text-center p-2'>value: Float32</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0xcdcc8c3f</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className='space-y-2'>
                      <p>Infinity</p>
                      <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                        <thead className='bg-slate-50'>
                          <tr>
                            <th className='border-collapse border border-slate-400 text-center p-2'>value: Float32</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0x0000807f</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className='space-y-2'>
                      <p>NaN</p>
                      <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                        <thead className='bg-slate-50'>
                          <tr>
                            <th className='border-collapse border border-slate-400 text-center p-2'>value: Float32</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0x0000c07f</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </section>

                <section>
                  <h3 className='text-xl font-semibold'>Float64</h3>
                  <p className='mt-1 after:text-slate-500 after:content-[":_Data_Size"] after:text-xs after:ml-1'>8 bytes</p>
                  <p className='mt-4'>
                    64bit float. Stores IEEE 754 double precision floating point number format in little endian.
                  </p>
                  <div className={classNames.dataStructure}>
                    <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                      <thead className='bg-slate-50'>
                        <tr>
                          <th className='border-collapse border border-slate-400 text-center p-2'>value: Float64</th>
                        </tr>
                      </thead>
                      <tbody className='bg-white'>
                        <tr>
                          <td className='border-collapse border border-slate-400 text-center p-2'>serialize_body(value: Float64)</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className={classNames.example}>
                    <div className='space-y-2'>
                      <p>-Infinity</p>
                      <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                        <thead className='bg-slate-50'>
                          <tr>
                            <th className='border-collapse border border-slate-400 text-center p-2'>value: Float64</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0x000000000000f0ff</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className='space-y-2'>
                      <p>-1.1</p>
                      <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                        <thead className='bg-slate-50'>
                          <tr>
                            <th className='border-collapse border border-slate-400 text-center p-2'>value: Float64</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0x9a9999999999f1bf</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className='space-y-2'>
                      <p>0</p>
                      <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                        <thead className='bg-slate-50'>
                          <tr>
                            <th className='border-collapse border border-slate-400 text-center p-2'>value: Float64</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0x0000000000000000</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className='space-y-2'>
                      <p>1.1</p>
                      <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                        <thead className='bg-slate-50'>
                          <tr>
                            <th className='border-collapse border border-slate-400 text-center p-2'>value: Float64</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0x9a9999999999f13f</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className='space-y-2'>
                      <p>Infinity</p>
                      <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                        <thead className='bg-slate-50'>
                          <tr>
                            <th className='border-collapse border border-slate-400 text-center p-2'>value: Float64</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0x000000000000f07f</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className='space-y-2'>
                      <p>NaN</p>
                      <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                        <thead className='bg-slate-50'>
                          <tr>
                            <th className='border-collapse border border-slate-400 text-center p-2'>value: Float64</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0x000000000000f87f</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </section>

                <section>
                  <h3 className='text-xl font-semibold'>BigUInt</h3>
                  <p className='mt-1 after:text-slate-500 after:content-[":_Data_Size"] after:text-xs after:ml-1'>1 ~ N bytes</p>
                  <p className='mt-4'>
                    Unsigned BigInt. The first byte is a BigUInt byte number ( <a href='#body-uint64' className={classNames.link}>UInt64</a> ) , followed by a little-endian unsigned BigInt. If the value of BigUInt is 0, the byte number part will be filled with 0 and the BigUInt part will not store anything.
                  </p>
                  <div className={classNames.dataStructure}>
                    <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                      <thead className='bg-slate-50'>
                        <tr>
                          <th className='border-collapse border border-slate-400 text-center p-2'>Bytes of serialized BigUint value: UInt64</th>
                          <th className='border-collapse border border-slate-400 text-center p-2'>value: BigUInt</th>
                        </tr>
                      </thead>
                      <tbody className='bg-white'>
                        <tr>
                          <td className='border-collapse border border-slate-400 text-center p-2'>serialize_body(Bytes of serialized BigUint value: UInt64)</td>
                          <td className='border-collapse border border-slate-400 text-center p-2'>serialize_body(value: BigUInt)</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className={classNames.example}>
                    <div className='space-y-2'>
                      <p>0</p>
                      <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                        <thead className='bg-slate-50'>
                          <tr>
                            <th className='border-collapse border border-slate-400 text-center p-2'>Bytes of serialized BigUint value: UInt64</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0x00</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className='space-y-2'>
                      <p>1234567890</p>
                      <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                        <thead className='bg-slate-50'>
                          <tr>
                            <th className='border-collapse border border-slate-400 text-center p-2'>Bytes of serialized BigUint value: UInt64</th>
                            <th className='border-collapse border border-slate-400 text-center p-2'>value: BigUInt</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0x04</td>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0xd2029649</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </section>

                <section id='body-bigint'>
                  <h3 className='text-xl font-semibold'>BigInt</h3>
                  <p className='mt-1 after:text-slate-500 after:content-[":_Data_Size"] after:text-xs after:ml-1'>1 ~ N bytes</p>
                  <p className='mt-4'>
                    BigInt. The first byte is a BigInt byte number ( <a href='#body-uint64' className={classNames.link}>UInt64</a> ) , followed by a little-endian BigInt. If the value of BigInt is 0, the byte number part will be filled with 0 and the BigInt part will not store anything.
                  </p>
                  <div className={classNames.dataStructure}>
                    <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                      <thead className='bg-slate-50'>
                        <tr>
                          <th className='border-collapse border border-slate-400 text-center p-2'>Bytes of serialized BigInt value: UInt64</th>
                          <th className='border-collapse border border-slate-400 text-center p-2'>value: BigInt</th>
                        </tr>
                      </thead>
                      <tbody className='bg-white'>
                        <tr>
                          <td className='border-collapse border border-slate-400 text-center p-2'>serialize_body(Bytes of serialized BigInt value: UInt64)</td>
                          <td className='border-collapse border border-slate-400 text-center p-2'>serialize_body(value: BigInt)</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className={classNames.example}>
                    <div className='space-y-2'>
                      <p>0</p>
                      <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                        <thead className='bg-slate-50'>
                          <tr>
                            <th className='border-collapse border border-slate-400 text-center p-2'>Bytes of serialized BigInt value: UInt64</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0x00</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className='space-y-2'>
                      <p>1234567890</p>
                      <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                        <thead className='bg-slate-50'>
                          <tr>
                            <th className='border-collapse border border-slate-400 text-center p-2'>Bytes of serialized BigInt value: UInt64</th>
                            <th className='border-collapse border border-slate-400 text-center p-2'>value: BigInt</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0x04</td>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0xd2029649</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className='space-y-2'>
                      <p>-1234567890</p>
                      <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                        <thead className='bg-slate-50'>
                          <tr>
                            <th className='border-collapse border border-slate-400 text-center p-2'>Bytes of serialized BigInt value: UInt64</th>
                            <th className='border-collapse border border-slate-400 text-center p-2'>value: BigInt</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0x04</td>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0x2efd69b6</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </section>

                <section>
                  <h3 className='text-xl font-semibold'>BigDecimal</h3>
                  <p className='mt-1 after:text-slate-500 after:content-[":_Data_Size"] after:text-xs after:ml-1'>1 ~ N bytes</p>
                  <p className='mt-4'>
                    BigDecimal. Stores an arbitrary precision integer unscaled value ( <a href='#body-bigint' className={classNames.link}>BigInt</a> ) and a scale ( <a href='#body-int64' className={classNames.link}>Int64</a> ).
                  </p>

                  <div className={classNames.dataStructure}>
                    <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                      <thead className='bg-slate-50'>
                        <tr>
                          <th className='border-collapse border border-slate-400 text-center p-2'>unscaled value: BigInt</th>
                          <th className='border-collapse border border-slate-400 text-center p-2'>scale: Int64</th>
                        </tr>
                      </thead>
                      <tbody className='bg-white'>
                        <tr>
                          <td className='border-collapse border border-slate-400 text-center p-2'>serialize_body(unscaled value: BigInt)</td>
                          <td className='border-collapse border border-slate-400 text-center p-2'>serialize_body(scale: Int64)</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <div className={classNames.example}>
                    <div className='space-y-2'>
                      <p>0</p>
                      <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                        <thead className='bg-slate-50'>
                          <tr>
                            <th className='border-collapse border border-slate-400 text-center p-2'>unscaled value: BigInt</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0x00</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className='space-y-2'>
                      <p>1.23</p>
                      <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                        <thead className='bg-slate-50'>
                          <tr>
                            <th className='border-collapse border border-slate-400 text-center p-2'>unscaled value: BigInt</th>
                            <th className='border-collapse border border-slate-400 text-center p-2'>scale: Int64</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0x017b</td>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0x04</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className='space-y-2'>
                      <p>-1.23</p>
                      <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                        <thead className='bg-slate-50'>
                          <tr>
                            <th className='border-collapse border border-slate-400 text-center p-2'>unscaled value: BigInt</th>
                            <th className='border-collapse border border-slate-400 text-center p-2'>scale: Int64</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0x0185</td>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0x04</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </section>

                <section>
                  <h3 className='text-xl font-semibold'>String</h3>
                  <p className='mt-1 after:text-slate-500 after:content-[":_Data_Size"] after:text-xs after:ml-1'>1 ~ N bytes</p>
                  <p className='mt-4'>
                    UTF-8 String. Stores a byte number of a string value ( <a href='#body-uint64' className={classNames.link}>UInt64</a> ) and the string value. If the string is empty, the string part will not store anything.
                  </p>
                  <div className={classNames.dataStructure}>
                    <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                      <thead className='bg-slate-50'>
                        <tr>
                          <th className='border-collapse border border-slate-400 text-center p-2'>Bytes of serialized String value: UInt64</th>
                          <th className='border-collapse border border-slate-400 text-center p-2'>value: String</th>
                        </tr>
                      </thead>
                      <tbody className='bg-white'>
                        <tr>
                          <td className='border-collapse border border-slate-400 text-center p-2'>serialize_body(Bytes of serialized String value: UInt64)</td>
                          <td className='border-collapse border border-slate-400 text-center p-2'>serialize_body(value: String)</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <div className={classNames.example}>
                    <div className='space-y-2'>
                      <p>""</p>
                      <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                        <thead className='bg-slate-50'>
                          <tr>
                            <th className='border-collapse border border-slate-400 text-center p-2'>Bytes of serialized String value: UInt64</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0x00</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className='space-y-2'>
                      <p>"Test"</p>
                      <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                        <thead className='bg-slate-50'>
                          <tr>
                            <th className='border-collapse border border-slate-400 text-center p-2'>Bytes of serialized String value: UInt64</th>
                            <th className='border-collapse border border-slate-400 text-center p-2'>value: String</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0x04</td>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0x54657374</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </section>

                <section>
                  <h3 className='text-xl font-semibold'>Binary</h3>
                  <p className='mt-1 after:text-slate-500 after:content-[":_Data_Size"] after:text-xs after:ml-1'>1 ~ N bytes</p>
                  <p className='mt-4'>
                    Binary. Stores a byte number of a byte sequence ( <a href='#body-uint64' className={classNames.link}>UInt64</a> ) and the byte sequence. When the byte sequence is empty, nothing is stored in the byte sequence section.
                  </p>
                  <div className={classNames.dataStructure}>
                    <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                      <thead className='bg-slate-50'>
                        <tr>
                          <th className='border-collapse border border-slate-400 text-center p-2'>Bytes of serialized Binary value: UInt64</th>
                          <th className='border-collapse border border-slate-400 text-center p-2'>value: Binary</th>
                        </tr>
                      </thead>
                      <tbody className='bg-white'>
                        <tr>
                          <td className='border-collapse border border-slate-400 text-center p-2'>serialize_body(Bytes of serialized Binary value: UInt64)</td>
                          <td className='border-collapse border border-slate-400 text-center p-2'>serialize_body(value: Binary)</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <div className={classNames.example}>
                    <div className='space-y-2'>
                      <p>[]</p>
                      <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                        <thead className='bg-slate-50'>
                          <tr>
                            <th className='border-collapse border border-slate-400 text-center p-2'>Bytes of serialized Binary value: UInt64</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0x00</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className='space-y-2'>
                      <p>[1, 2, 3]</p>
                      <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                        <thead className='bg-slate-50'>
                          <tr>
                            <th className='border-collapse border border-slate-400 text-center p-2'>Bytes of serialized Binary value: UInt64</th>
                            <th className='border-collapse border border-slate-400 text-center p-2'>value: Binary</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0x03</td>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0x010203</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </section>

                <section>
                  <h3 className='text-xl font-semibold'>Array&lt;T&gt;</h3>
                  <p className='mt-1 after:text-slate-500 after:content-[":_Data_Size"] after:text-xs after:ml-1'>1 ~ N bytes</p>
                  <p className='mt-4'>
                    An array of type T. Stores a number of elements ( <a href='#body-uint64' className={classNames.link}>UInt64</a> ) and the encoded values of all T types in order.
                  </p>
                  <div className={classNames.dataStructure}>
                    <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                      <thead className='bg-slate-50'>
                        <tr>
                          <th className='border-collapse border border-slate-400 text-center p-2'>Number of values in Array: UInt64</th>
                          <th className='border-collapse border border-slate-400 text-center p-2'>Array[0] value: T</th>
                          <th className='border-collapse border border-slate-400 text-center p-2'>Array[..] value: T</th>
                        </tr>
                      </thead>
                      <tbody className='bg-white'>
                        <tr>
                          <td className='border-collapse border border-slate-400 text-center p-2'>serialize_body(Number of values in Array: UInt64)</td>
                          <td className='border-collapse border border-slate-400 text-center p-2'>serialize_body(Array[0] value: T)</td>
                          <td className='border-collapse border border-slate-400 text-center p-2'>serialize_body(Array[..] value: T)..</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <div className={classNames.example}>
                    <div className='space-y-2'>
                      <p>[]: Array&lt;UInt8&gt;</p>
                      <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                        <thead className='bg-slate-50'>
                          <tr>
                            <th className='border-collapse border border-slate-400 text-center p-2'>Number of values in Array: UInt64</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0x00</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className='space-y-2'>
                      <p>[1, 2, 3]: Array&lt;UInt8&gt;</p>
                      <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                        <thead className='bg-slate-50'>
                          <tr>
                            <th className='border-collapse border border-slate-400 text-center p-2'>Number of values in Array: UInt64</th>
                            <th className='border-collapse border border-slate-400 text-center p-2'>Array[0] value: UInt8</th>
                            <th className='border-collapse border border-slate-400 text-center p-2'>Array[1] value: UInt8</th>
                            <th className='border-collapse border border-slate-400 text-center p-2'>Array[2] value: UInt8</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0x03</td>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0x01</td>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0x02</td>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0x03</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </section>

                <section>
                  <h3 className='text-xl font-semibold'>Tuple&lt;(T1, ..)&gt;</h3>
                  <p className='mt-1 after:text-slate-500 after:content-[":_Data_Size"] after:text-xs after:ml-1'>N bytes</p>
                  <p className='mt-4'>
                    Tuple. Stores the encoded values in the order of their definitions.
                  </p>
                  <div className={classNames.dataStructure}>
                    <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                      <thead className='bg-slate-50'>
                        <tr>
                          <th className='border-collapse border border-slate-400 text-center p-2'>value: T1</th>
                          <th className='border-collapse border border-slate-400 text-center p-2'>value: T..</th>
                        </tr>
                      </thead>
                      <tbody className='bg-white'>
                        <tr>
                          <td className='border-collapse border border-slate-400 text-center p-2'>serialize_body(value: T1)</td>
                          <td className='border-collapse border border-slate-400 text-center p-2'>serialize_body(value: T)..</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <div className={classNames.example}>
                    <div className='space-y-2'>
                      <p>(123, "Test"): (UInt8, String)</p>
                      <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                        <thead className='bg-slate-50'>
                          <tr>
                            <th className='border-collapse border border-slate-400 text-center p-2'>value: UInt8</th>
                            <th className='border-collapse border border-slate-400 text-center p-2'>value: String</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0x7b</td>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0x0454657374</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </section>

                {/* <section>
                  <h3 className='text-xl font-semibold'>Struct {'{'} field1: T1, field..: T.. {'}'}</h3>
                  <p className='mt-1 after:text-slate-500 after:content-[":_Data_Size"] after:text-xs after:ml-1'>N bytes</p>
                  <p className='mt-4'>
                    Struct. Encodes and stores all values in the order of their definition.
                  </p>
                  <div className={classNames.dataStructure}>
                    <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                      <thead className='bg-slate-50'>
                        <tr>
                          <th className='border-collapse border border-slate-400 text-center p-2'>value: T1</th>
                          <th className='border-collapse border border-slate-400 text-center p-2'>value..: T..</th>
                        </tr>
                      </thead>
                      <tbody className='bg-white'>
                        <tr>
                          <td className='border-collapse border border-slate-400 text-center p-2'>serialize_body(value: T1)</td>
                          <td className='border-collapse border border-slate-400 text-center p-2'>serialize_body(value: T..)..</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <div className='bg-slate-100 p-4 mt-6 before:content-["example"] relative before:absolute before:top-1 before:right-2 before:text-slate-500'>
                    <div className='space-y-2'>
                      <p>{'{'} field1: true, field2: 123, field3: "Test" {'}'}: Struct {'{'} field1: Boolean, field2: UInt8, field3: String {'}'}</p>
                      <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                        <thead className='bg-slate-50'>
                          <tr>
                            <th className='border-collapse border border-slate-400 text-center p-2'>value: Boolean</th>
                            <th className='border-collapse border border-slate-400 text-center p-2'>value: UInt8</th>
                            <th className='border-collapse border border-slate-400 text-center p-2'>value: String</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0x01</td>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0x7b</td>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0x0454657374</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </section> */}

                <section>
                  <h3 className='text-xl font-semibold'>Map&lt;T&gt;</h3>
                  <p className='mt-1 after:text-slate-500 after:content-[":_Data_Size"] after:text-xs after:ml-1'>1 ~ N bytes</p>
                  <p className='mt-4'>
                    Map. Stores a number of elements ( <a href='#body-uint64' className={classNames.link}>UInt64</a> ), keys and values. The keys can only store String and all the values must be the same type. Encodes all the elements in order of key and value.
                  </p>
                  <div className={classNames.dataStructure}>
                    <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                      <thead className='bg-slate-50'>
                        <tr>
                          <th className='border-collapse border border-slate-400 text-center p-2'>Number of elements in Map: UInt64</th>
                          <th className='border-collapse border border-slate-400 text-center p-2'>key: String</th>
                          <th className='border-collapse border border-slate-400 text-center p-2'>value: T</th>
                          <th className='border-collapse border border-slate-400 text-center p-2'>key: String..</th>
                          <th className='border-collapse border border-slate-400 text-center p-2'>value: T..</th>
                        </tr>
                      </thead>
                      <tbody className='bg-white'>
                        <tr>
                          <td className='border-collapse border border-slate-400 text-center p-2'>serialize_body(Number of elements in Map: UInt64)</td>
                          <td className='border-collapse border border-slate-400 text-center p-2'>serialize_body(key: String)</td>
                          <td className='border-collapse border border-slate-400 text-center p-2'>serialize_body(value: T)</td>
                          <td className='border-collapse border border-slate-400 text-center p-2'>serialize_body(key: String)..</td>
                          <td className='border-collapse border border-slate-400 text-center p-2'>serialize_body(value: T)..</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <div className='bg-slate-100 p-4 mt-6 before:content-["example"] relative before:absolute before:top-1 before:right-2 before:text-slate-500'>
                    <div className='space-y-2'>
                      <p>Map {'{'} "field1": true, "field2", false {'}'}: Map&lt;Boolean&gt;</p>
                      <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                        <thead className='bg-slate-50'>
                          <tr>
                            <th className='border-collapse border border-slate-400 text-center p-2'>Number of elements in Map: UInt64</th>
                            <th className='border-collapse border border-slate-400 text-center p-2'>key: String</th>
                            <th className='border-collapse border border-slate-400 text-center p-2'>value: Boolean</th>
                            <th className='border-collapse border border-slate-400 text-center p-2'>key: String</th>
                            <th className='border-collapse border border-slate-400 text-center p-2'>value: Boolean</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0x02</td>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0x066669656c6432</td>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0x00</td>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0x066669656c6431</td>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0x01</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </section>

                <section>
                  <h3 className='text-xl font-semibold'>Enum {'{'} Field1(T1), Field..(T..) {'}'}</h3>
                  <p className='mt-1 after:text-slate-500 after:content-[":_Data_Size"] after:text-xs after:ml-1'>1 ~ N bytes</p>
                  <p className='mt-4'>
                    Enum. Stores a field number ( <a href='#body-uint64' className={classNames.link}>UInt64</a> ) that starts from 0. Then, encodes and stores the values in the field.
                  </p>
                  <div className={classNames.dataStructure}>
                    <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                      <thead className='bg-slate-50'>
                        <tr>
                          <th className='border-collapse border border-slate-400 text-center p-2'>Field number: UInt64</th>
                          <th className='border-collapse border border-slate-400 text-center p-2'>Field value: T..</th>
                        </tr>
                      </thead>
                      <tbody className='bg-white'>
                        <tr>
                          <td className='border-collapse border border-slate-400 text-center p-2'>serialize_body(Field number: UInt64)</td>
                          <td className='border-collapse border border-slate-400 text-center p-2'>serialize_body(Field value: T..)</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <div className='bg-slate-100 p-4 mt-6 before:content-["example"] relative before:absolute before:top-1 before:right-2 before:text-slate-500'>
                    <div className='space-y-2'>
                      <p>Enum::B(123): Enum {'{'} A(Boolean), B(UInt8), C(Boolean, String) {'}'}</p>
                      <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                        <thead className='bg-slate-50'>
                          <tr>
                            <th className='border-collapse border border-slate-400 text-center p-2'>Field number: UInt64</th>
                            <th className='border-collapse border border-slate-400 text-center p-2'>Field value: UInt8</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0x01</td>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0x7b</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </section>

                <section>
                  <h3 className='text-xl font-semibold'>Date</h3>
                  <p className='mt-1 after:text-slate-500 after:content-[":_Data_Size"] after:text-xs after:ml-1'>2 ~ N bytes</p>
                  <p className='mt-4'>
                    Date. Stores a year ( <a href='#body-int32' className={classNames.link}>Int32</a> ) which represents 2000 as a value 0, followed by an ordinal ( <a href='#body-int16' className={classNames.link}>Int16</a> ) which represents January 1 as a value 0.
                  </p>
                  <div className={classNames.dataStructure}>
                    <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                      <thead className='bg-slate-50'>
                        <tr>
                          <th className='border-collapse border border-slate-400 text-center p-2'>Year value: Int32</th>
                          <th className='border-collapse border border-slate-400 text-center p-2'>Ordinal value: UInt16</th>
                        </tr>
                      </thead>
                      <tbody className='bg-white'>
                        <tr>
                          <td className='border-collapse border border-slate-400 text-center p-2'>serialize_body(Year value: Int32)</td>
                          <td className='border-collapse border border-slate-400 text-center p-2'>serialize_body(Ordinal value: UInt16)</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <div className={classNames.example}>
                    <div className='space-y-2'>
                      <p>2000-01-01</p>
                      <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                        <thead className='bg-slate-50'>
                          <tr>
                            <th className='border-collapse border border-slate-400 text-center p-2'>Year value: Int32</th>
                            <th className='border-collapse border border-slate-400 text-center p-2'>Ordinal value: UInt16</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0x00</td>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0x00</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className='space-y-2'>
                      <p>2020-08-04</p>
                      <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                        <thead className='bg-slate-50'>
                          <tr>
                            <th className='border-collapse border border-slate-400 text-center p-2'>Year value: Int32</th>
                            <th className='border-collapse border border-slate-400 text-center p-2'>Ordinal value: UInt16</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0x28</td>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0x9803</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </section>

                <section>
                  <h3 className='text-xl font-semibold'>DateTime</h3>
                  <p className='mt-1 after:text-slate-500 after:content-[":_Data_Size"] after:text-xs after:ml-1'>2 ~ N bytes</p>
                  <p className='mt-4'>
                    DateTime. Stores seconds ( <a href='#body-int64' className={classNames.link}>Int64</a> ) and nanoseconds ( <a href='#body-uint32' className={classNames.link}>UInt32</a> ) that represent the time passed since 1970-01-01 00:00:00 UTC.
                  </p>
                  <div className={classNames.dataStructure}>
                    <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                      <thead className='bg-slate-50'>
                        <tr>
                          <th className='border-collapse border border-slate-400 text-center p-2'>Seconds value: Int64</th>
                          <th className='border-collapse border border-slate-400 text-center p-2'>Nano seconds value: UInt32</th>
                        </tr>
                      </thead>
                      <tbody className='bg-white'>
                        <tr>
                          <td className='border-collapse border border-slate-400 text-center p-2'>serialize_body(Seconds value: Int64)</td>
                          <td className='border-collapse border border-slate-400 text-center p-2'>serialize_body(Nano seconds value: UInt32)</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <div className={classNames.example}>
                    <div className='space-y-2'>
                      <p>1970-01-01 00:00:00.000000000</p>
                      <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                        <thead className='bg-slate-50'>
                          <tr>
                            <th className='border-collapse border border-slate-400 text-center p-2'>Seconds value: Int64</th>
                            <th className='border-collapse border border-slate-400 text-center p-2'>Nano seconds value: UInt32</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0x00</td>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0x00</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className='space-y-2'>
                      <p>2020-08-04 12:34:56.123456789</p>
                      <table className='border-collapse border border-slate-400 table-fixed break-words w-full lg:w-auto'>
                        <thead className='bg-slate-50'>
                          <tr>
                            <th className='border-collapse border border-slate-400 text-center p-2'>Seconds value: Int64</th>
                            <th className='border-collapse border border-slate-400 text-center p-2'>Nano seconds value: UInt32</th>
                          </tr>
                        </thead>
                        <tbody className='bg-white'>
                          <tr>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0xf07c55ca17</td>
                            <td className='border-collapse border border-slate-400 text-center p-2'>0xe5d1bc75</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </section>
              </div>
            </section>
            <p className='mt-6 text-right text-rose-50 after:content-["Thank_you_for_reading_to_the_end"]'></p>
          </main>
        </div>
      </div>
    </>
  )
}

export default IndexPage
